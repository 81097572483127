var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"content-area fixed top-0 fill-width z-index-1 white d-flex align-center",staticStyle:{"height":"44px"}},[_c('div',{staticClass:"d-flex justify-space-between fill-width align-center px-10"},[_c('v-icon',{staticClass:"mr-4",attrs:{"small":""},on:{"click":_setup.onClickPrevIcon}},[_vm._v(" $vuetify.icons.prevIcon ")]),_c('span',{staticClass:"oneLine rixgo-extrabold align-center gray900--text font-g16-24"},[_vm._v(_vm._s(_setup.event?.title))]),_c('div',{staticClass:"pb-1",on:{"click":_setup.onClickShareIcon}},[_c('v-icon',[_vm._v("$vuetify.icons.shareIcon")])],1)],1)]),(_setup.event?.isClosed)?_c('div',{staticClass:"fill-width pt-40 pb-18",staticStyle:{"background":"black"}},[_vm._m(0)]):_vm._e(),_c('div',{class:_setup.event?.isClosed ? '' : 'mt-22'},[_c('v-img',{attrs:{"src":_setup.event?.mainImage,"eager":""},on:{"load":function($event){return _setup.onLoadImage()}}}),_vm._l((_setup.event?.introduceImageList ?? []),function(item,index){return _c('v-img',{key:item + index,attrs:{"src":item,"eager":""},on:{"~load":function($event){return _setup.onLoadImage()}}})}),_c('v-img',{attrs:{"src":_setup.event?.giveawayImage,"eager":""},on:{"load":function($event){return _setup.onLoadImage()}}}),_c('v-img',{attrs:{"src":_setup.event?.guideImage,"eager":""},on:{"load":function($event){return _setup.onLoadImage()}}}),_c('v-img',{attrs:{"src":_setup.event?.appDownloadImage,"eager":""},on:{"load":function($event){return _setup.onLoadImage()}}}),(_setup.event?.appDownloadButtonInfo?.isUse && _setup.deviceInfo === null)?_c('div',{staticClass:"py-14 pl-35 pr-35",style:(`background-color: ${_setup.event?.appDownloadButtonInfo.backgroundColor}`)},[_c(_setup.CellookButton,{attrs:{"backgroundColor":_setup.event?.appDownloadButtonInfo.buttonColor,"borderRadius":`${
          _setup.event?.appDownloadButtonInfo.type === 'O' ? '100px' : ''
        }`},on:{"click":function($event){return _setup.moveToLink(_setup.event?.appDownloadButtonInfo?.link ?? '')}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',{staticClass:"oneLine font-g14-20 rixgo-medium",style:(`color:${_setup.event?.appDownloadButtonInfo.textColor};`)},[_vm._v(_vm._s(_setup.event?.appDownloadButtonInfo?.text))])]},proxy:true}],null,false,3564980090)})],1):_vm._e(),_c('div',{staticStyle:{"padding-bottom":"92px"}},[_c('v-img',{attrs:{"src":_setup.event?.cautionImage,"eager":""},on:{"~load":function($event){return _setup.onLoadImage()}}})],1),(_setup.event?.bottomButtonInfoList.length > 0)?_c('div',{class:`fixed fill-width bottom-0 ${
        _setup.event?.bottomButtonInfoList.length > 1 ? 'py-10' : 'pa-10'
      } white`,staticStyle:{"bottom":"75px","max-width":"600px"}},[_c('div',{staticClass:"fill-width d-flex px-8"},_vm._l((_setup.event?.bottomButtonInfoList.filter(
            (value) => value.isUse
          ) ?? []),function(item,index){return _c('div',{key:index,class:`flex-1 text-center ${
            _setup.event?.bottomButtonInfoList.length > 1 ? 'px-2' : ''
          }`,style:(_setup.isShowFullBottomButton ? '' : 'max-width: 50%')},[_c(_setup.CellookButton,{attrs:{"backgroundColor":item.buttonColor,"borderRadius":"4px"},on:{"click":function($event){return _setup.onClickBottomButton(item, index)}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',{staticClass:"oneLine font-g14-20 rixgo-bold",style:(`color:${item.textColor};`)},[_vm._v(_vm._s(item.text)+" ")])]},proxy:true}],null,true)})],1)}),0)]):_vm._e()],2),_c(_setup.Alert,{attrs:{"show":_setup.alertInfo.show,"content":_setup.alertInfo.content},on:{"onClose":_setup.onCloseAlert}}),_c(_setup.Sharing,{attrs:{"store":_setup.event?.shareInfo.title,"content":'',"url":_setup.shareUrl,"show":_setup.shareShow,"shareItem":{
      ..._setup.event?.shareInfo,
      url: _setup.shareUrl,
      imageUrl: _setup.event?.shareInfo.thumbnail,
      description: '',
    },"sheetTitle":"이벤트"},on:{"onClose":function($event){_setup.shareShow = false}}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"white--text"},[_c('div',{staticClass:"d-flex justify-center font-g14-20 rixgo-bold pb-2"},[_vm._v(" 종료된 이벤트입니다. ")]),_c('div',{staticClass:"d-flex justify-center font-g12-18 rixgo-regular"},[_vm._v(" 당첨자는 발표일에 공지사항 게시판에서 확인하실 수 있습니다. ")])])
}]

export { render, staticRenderFns }